<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">OFFICIAL RECEIPT RE-ASSIGN</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table dense
                    :headers="headers"
                    :items="transmital_data"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td class="text-center">
              {{ item.collector_name }}
            </td>
            <td class="text-center">
              {{ item.stab_no }}
            </td>
            <td class="text-center">
              {{ item.start+' - '+item.end }}
            </td>
            <td class="text-center">
              {{ item.address }}
            </td>
            <td class="text-center">
              {{ item.date_release}}
            </td>
            <td class="text-center">
              {{ item.last_used}}
            </td>
            <td class="text-center">
              {{ item.history}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="re_assigned_dialog" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">SELECT NEW COLLECTOR</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-row>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="transmital_no"
                    label="Transmital #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="stab_no"
                    label="Stab #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="or_series"
                    label="OR Series"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="or_address"
                    label="OR Address"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="new_date_release"
                    dense
                    label="Date"
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="new_collector_id"
                    dense
                    outlined
                    label="Collector"
                    :items="collectors"
                    item-value="id"
                    item-text="full_name"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-4"
                  @click="load_or_series"
                  v-if="!saving"
                >
                  Save changes
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      this.init()
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'COLLECTOR NAME', value: 'date_of_deposit', sortable: false},
          {text: 'STAB #', value: 'date_of_deposit', sortable: false},
          {text: 'OR SERIES', value: 'time_of_deposit', sortable: false},
          {text: 'OR ADDRESS', value: 'amount', sortable: false},
          {text: 'DATE  RELEASED', value: 'bank_code_name', sortable: false},
          {text: 'LAST USED', value: 'bank_code_name', sortable: false},
          {text: 'HISTORY', value: 'bank_code_name', sortable: false},],
        re_assigned_dialog: false,
        key: 0,

        new_collector_id: 0,
        last_used: 0,
        new_date_release: '',

        saving: false,
        alert: false,
        alert_message: '',

        data: [],
        collectors: [],
        collector_id: 0,
        transmital_ors_id: 0,
        transmital_no: 0,
        stab_no: 0,
        or_series: '',
        or_address: '',
        date_release: '',

        transmital_data: [],
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deposit_slips', ['remittances_data', 'delete_remittances']),
      ...mapActions('transmital_or', ['official_receipt_not_yet_done', 're_assigned_or']),
      init() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.official_receipt_not_yet_done(data)
          .then(response => {
            this.transmital_data = response.data[0].or
            this.collectors = response.data[0].collectors
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.collectors = []
        this.collector_id = 0
        this.transmital_ors_id = 0
        this.transmital_no = 0
        this.stab_no = 0
        this.or_series = ''
        this.or_address = ''
        this.date_release = ''

        this.new_collector_id = 0
        this.last_used = 0
        this.new_date_release = ''
      },
      load_or_series() {
        this.saving = true
        this.alert = false
        if (this.transmital_ors_id == 0) {
          this.alert = true
          this.alert_message = 'Please request to Head Office (TO LOAD TRANSMITAL OR SERIES)'
          this.saving = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('transmital_or_id', this.transmital_ors_id);
            data.append('new_collector_id', this.new_collector_id);
            data.append('collector_id', this.collector_id);
            data.append('last_used', this.last_used);
            data.append('new_date_release', moment(this.new_date_release, 'YYYY-MM-DD').format('MMMM D, YYYY'));
            data.append('date_release', this.date_release);

            this.re_assigned_or(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.init()
                this.reset()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      },
      activerow(value) {
        this.date_release = value.date_release
        this.collector_id = value.collector_id
        this.last_used = value.last_used
        this.transmital_ors_id = value.id
        this.re_assigned_dialog = true
        this.selectedDepositId = value.id;


        this.transmital_no = value.transmital_no
        this.stab_no = value.stab_no
        this.or_series = value.start + '-' + value.end
        this.or_address = value.address
      },
    }
  }
</script>
